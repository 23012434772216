import http from '@/utils/http'
// 获取预设消息分组
export const messageGroup = (data) => {
  return http.get('hxb-backend/qyWx/messageGroup', { params: data })
}
// 获取预设消息
export const getMessage= (data) => {
  return http.get(`hxb-backend/qyWx/message`, { params: data } )
}
// 查看消息
export const getMessageDetails= (id,data) => {
  return http.get(`hxb-backend/qyWx/message/${id}`, { params: data } )
}