<!--
 * @Author: your name
 * @Date: 2021-05-28 09:59:14
 * @LastEditTime: 2021-06-09 10:39:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\views\previewHtml.vue
-->
<template>
<div class="preview_html">
  <div v-html="details.content"></div>
  <van-button v-if="type!=1" class="send_btn_long" type="primary" @click="sendMsg" :loading="loading">发送</van-button>
</div>
</template>
<script>
import { initWechat } from '@/utils/weChatConfig'
import { useStore } from "vuex";
import {Button} from 'vant'
import {reactive, toRefs} from 'vue'
import {useRoute} from 'vue-router'
import {getMessageDetails} from '@/api/fastReply'
import {sendChatMessage} from '@/utils/weChatConfig'
import {ossUrl,localUrl,redirectQyWxCode} from '@/utils/urls'
import notify from '@/vant/notify'
export default {
  name: 'PreviewHtml',
  components:{
    [Button.name]:Button
  },
  setup() {
    const store=useStore()
    const route = useRoute()
    const stateObj=reactive({
      details:{},
      show:true,
      type:null,
      loading:false,
      ready:false //是否初始化好企业微信配置
    })
    const init=()=>{
      let url=null
      if(window._wxjs_is_wkwebview){
        url=store.state.common.localUrl
      }
      initWechat(url).then(()=>{
        stateObj.ready=true
      })
    }
    const getDetails=()=>{
      let {state,id}=route.query
      let obj
      obj=state?JSON.parse(state):{}
      const {contentId,customerQyWxUserId,type,positionId}=obj
      const QyWxUserId=localStorage.getItem('customerQyWxUserId') || customerQyWxUserId
      id=id ||contentId
      stateObj.type=type

      console.log(id,type,QyWxUserId,positionId)
      
      const params={
        customerQyWxUserId:type==1?QyWxUserId:null,
        positionId
      }

      getMessageDetails(id,params).then(res=>{
        if(res&&res.body){
          stateObj.details=res.body
          document.title=stateObj.details.title
        }else{
          stateObj.details={}
        }
      })
    }
    const sendMsg=()=>{
      if(!stateObj.ready){
        notify.warning('请等待初始化配置完成后再次尝试...')
        return
      }
      const customerQyWxUserId=localStorage.getItem('customerQyWxUserId')
      const {id,title,cover,content}=stateObj.details
      const desc=content.replace(/<.*?>/ig,"").slice(0,20)
      const positionId=localStorage.getItem('positionId')
      const para=JSON.stringify({contentId:id,customerQyWxUserId,positionId,type:1})
      const params={
        link: redirectQyWxCode(localUrl+'/previewHtml',para), //H5消息页面url 必填
        title, //H5消息标题
        desc, //H5消息摘要
        imgUrl: ossUrl+cover, //H5消息封面图片URL
      }
      stateObj.loading=true
      sendChatMessage('news',params).then(()=>{
        stateObj.loading=false
      })
    }
    getDetails()
    init()
    return {
      ...toRefs(stateObj),
      getDetails,
      sendMsg
    }
  }
}
</script>
<style lang="less" scoped>
  .preview_html{
    padding: 32px;
    padding-bottom: 64px;
    box-sizing: border-box;
    height: calc(~"100% - 88px");
    overflow: auto;
    :deep(a){
      pointer-events: none;
    }
    :deep(.send_btn_long){
      width: calc(~"100% - 64px");
      height: 88px;
      font-size: 34px;
      background: #1678ff;
      border-radius: 44px;
      position: fixed;
      bottom: 32px;
      left: 32px;
      right: 32px;
    }
    :deep(img){
      max-width: 100%;
      height:auto;
    }
  }
</style>